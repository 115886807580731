import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-28a3e2a4"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "MoveKeyView"
};
const _hoisted_2 = {
  class: "MoveKeyForm"
};
const _hoisted_3 = {
  key: 0
};
const _hoisted_4 = {
  key: 1
};
import { ref } from "vue";
import SelectLowAgent from "@/components/Form/SelectLowAgent";
import HeaderNavBar from "@/components/Header/HeaderNavBar";
import InputText from "@/components/Form/InputText";
import VantMsgLib from "@/utils/Vant/VantMsgLib";
import Apis_Key from "@/apis/Apis_Key";
import ApisLang from "@/locales/Apis/ApisLang";
import JumpResultPage from "@/utils/Components/JumpResultPage";

//[Mode]模式:1.批量迁移卡密(自己->代理商) 2.批量回迁卡密(代理商->自己) 3.回收卡密[自己卡密]

export default {
  __name: 'BatchKey',
  props: ['Mode'],
  setup(__props) {
    const props = __props;
    const Name = props.Mode == 1 ? '迁移卡密' : props.Mode == 2 ? '回迁卡密' : '回收卡密';
    const Options = {
      CacheDataField: 'KeyList',
      //缓存数据字段
      Name //[功能名字]用于提示用户是否执行该功能
    };

    const KeyType = ref('1'); //[提交类型] 1=卡密 2=条形码
    const UserName = ref(''); //目标代理商
    const OnSubmit = obj => {
      let arr = obj.arr || [];
      if (!arr.length) {
        VantMsgLib.alertError('请设置要操作的卡密');
        return;
      }
      console.log('OnSubmit', arr, KeyType.value);
      switch (Number(props.Mode)) {
        case 1:
          //批量迁移卡密[自己->下级代理]
          if (!UserName.value) {
            VantMsgLib.alertError('请设置新代理商');
            break;
          }
          Apis_Key.batchMoveKey(arr.toString(), UserName.value, Number(KeyType.value)).then(res => MakeResult(res, ApisLang('Key', 'batchMoveKey_state')));
          break;
        case 2:
          // 批量回迁卡密(代理商->自己)
          Apis_Key.batchMoveKeyBySubordinate(arr.toString()).then(
          //[fieldCodeLang]错误代码与(batchMoveKey)一致,所以无需单独设置语言字段
          res => MakeResult(res, ApisLang('Key', 'batchMoveKey_state')));
          break;
        case 3:
          //回收卡密
          Apis_Key.batchRecoveryKey(arr.toString(), Number(KeyType.value)).then(
          //[fieldCodeLang]错误代码与(batchMoveKey)一致,所以无需单独设置语言字段
          res => MakeResult(res, ApisLang('Key', 'batchRecoveryKey_state')));
          break;
      }
    };
    const MakeResult = (res, langField) => {
      if (!Apis_Key.checkResCode(res)) {
        return;
      }
      let arr = res.data;
      if (!arr.length) {
        VantMsgLib.alertError('执行成功,但解析结果发生异常,请联系客服');
        return;
      }
      console.log('MakeResult', res, langField);
      let result = arr.map(item => {
        return {
          ...item,
          msg: langField[Number(item.state)] || '!unknown'
        };
      });
      JumpResultPage(result);
    };
    return (_ctx, _cache) => {
      const _component_van_radio = _resolveComponent("van-radio");
      const _component_van_radio_group = _resolveComponent("van-radio-group");
      const _component_van_field = _resolveComponent("van-field");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(HeaderNavBar), {
        title: _unref(Name)
      }, null, 8, ["title"]), _createElementVNode("div", _hoisted_2, [props.Mode == 1 ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_unref(SelectLowAgent), {
        onSelectAgent: _cache[0] || (_cache[0] = user => UserName.value = user)
      })])) : _createCommentVNode("", true), _createVNode(_unref(InputText), {
        Options: Options,
        onOnSubmit: OnSubmit
      }), props.Mode != 2 ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_van_field, {
        name: "radio",
        label: "类型"
      }, {
        input: _withCtx(() => [_createVNode(_component_van_radio_group, {
          modelValue: KeyType.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => KeyType.value = $event),
          direction: "horizontal"
        }, {
          default: _withCtx(() => [_createVNode(_component_van_radio, {
            name: "1"
          }, {
            default: _withCtx(() => [_createTextVNode("卡密")]),
            _: 1
          }), _createVNode(_component_van_radio, {
            name: "2"
          }, {
            default: _withCtx(() => [_createTextVNode("条形码")]),
            _: 1
          })]),
          _: 1
        }, 8, ["modelValue"])]),
        _: 1
      })])) : _createCommentVNode("", true)])]);
    };
  }
};